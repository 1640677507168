import i18n from '@/i18n/index'

export default {
  formData: [{
    title: i18n.t('table.product.basicInformation'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'online',
      value: true,
      name: 'switch',
      label: i18n.t('form.product.online'),
      Object: {}
    }, {
      span: 24,
      prop: 'hotel_room_id',
      name: 'select',
      options: [],
      label: i18n.t('form.hotel.hotelRoom'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.hotel.hotelName') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.hotel.policyName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.hotel.roomName') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'price',
      name: 'input',
      label: i18n.t('form.hotel.price'),
      placeholder: i18n.t('form.hotel.unit'),
      Object: {
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.hotel.roomName') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'description',
      name: 'input',
      label: i18n.t('form.common.shortDescription'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.common.shortDescription') }) }
        ]
      }
    }]
  }]
}
